const sourcemappedStracktrace = require('sourcemapped-stacktrace');

const DEBOUNCE_DELAY = 5000;

const LEVELS = ['log', 'debug', 'error', 'trace'];

let logQueue = [],
	timeout = null;

const logger = {};

LEVELS.forEach(function(level) {
	logger[level] = function(...items) {
		const currentModule = (find('.page-content') && find('.page-content').id) || 'app',
			logLevelForModule = MODULE_CONFIG[currentModule] && MODULE_CONFIG[currentModule].logLevel;

		if (logLevelForModule && LEVELS.indexOf(logLevelForModule) > LEVELS.indexOf(level)) {
			return;
		}

		if (LOG_CONSOLE) {
			console[level].apply(console, items);
		}

		new Promise((resolve, reject) => {
			const logItems = [];

			Promise.all(items.map((item) => {
				if (item && typeof item === 'object' && !(item instanceof Error)) {
					logItems.push(JSON.stringify(item));
				} else if (item instanceof Error) {
					return new Promise((resolveStack, rejectStack) => {
						sourcemappedStracktrace.mapStackTrace(item.stack, function(stack) {
							logItems.push([`Error: ${item.message}`].concat(stack).join('\n'));

							resolveStack();
						});
					});
				} else {
					logItems.push(item);
				}
			})).then(function() {
				let logItem = logItems.join(' ');

				if (level === 'trace') {
					sourcemappedStracktrace.mapStackTrace(new Error().stack, function(stack) {
						stack.shift();
						stack.shift();
						stack.shift();

						logItem = [`Trace: ${logItem}`].concat(stack).join('\n');

						resolve(logItem);
					});
				} else {
					resolve(logItem);
				}
			});
		}).then(function(logItem) {
			if (timeout !== null) {
				clearTimeout(timeout);

				timeout = null;
			}

			logQueue.push({
				level: level === 'trace' ? 'log' : level, // Otherwise this will get "double traced" when it hits the server
				item: logItem
			});

			timeout = setTimeout(function() {
				amz.request('logger', { items: logQueue });

				logQueue = [];

				timeout = null;
			}, DEBOUNCE_DELAY);
		});
	}
});

module.exports = logger;