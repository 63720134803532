const logger = require('./logger');

if (document.location.hostname.indexOf('rounding.healthcare') !== -1) {
	var domainTokens = document.location.hostname.split('.');

	if (domainTokens.length > 3) {
		globalThis.default_accountId = domainTokens[0];
	}
} else if (document.location.hostname.indexOf('rounding.care') !== -1) {
	var domainTokens = document.location.hostname.split('.');

	if (domainTokens.length > 2) {
		globalThis.default_accountId = domainTokens[0];
	}
}

let errorMsg = document.getElementById('device-offline');
let launchWait = document.getElementById('launch-wait');
globalThis.global = (globalThis || window);

let params = {};
window.location.search.split('?').reverse()[0].split('&').map(p => p.split('=')).forEach(s => params[s[0]] = s[1]);
global.env = params.env;

window.addEventListener('error', function(event) {
    logger.error(event.error);
});

globalThis.loadResources = function() {
    launchWait.style.display = 'block';
    errorMsg.style.display = 'none';

    let req = new Request('/modules', {
        method:'POST',
        body: JSON.stringify({})
    });

    fetch(req).then(response => {
        return response.json();
    }).then(async result => {
        if (result.credentials) {
            globalThis.credentials = result.credentials;
        }

        let promises = [];

        (result.data || []).forEach(c => {
        	let path = MODULE_CONFIG[c.id].path.replace(/\\/g, '/');

            promises.push(Promise.all([
                import('../modules/' + path + '/' + c.id + '.js'),
            	import('../modules/' + path + '/config.json').catch(() => null),
            	import('../modules/' + path + '/' + c.id + '.html').catch(() => null),
            	import('../modules/' + path + '/' + c.id + '.css').catch(() => null)
        ]).then(function(moduleParts) {
            	if (moduleParts[2]) {
            		globalThis[c.id].html = moduleParts[2].default.replace(/\n/g, '').replace(/\t/g, '').replace(/    /g, '').replace(/\r/g, '');
            	}

            	if (moduleParts[3]) {
            		globalThis[c.id].style = moduleParts[3].default.replace(/\n/g, '').replace(/\t/g, '').replace(/    /g, '').replace(/\r/g, '');
            	}

            	globalThis[c.id].componentType = (moduleParts[1] && moduleParts[1].default.docType) || 'module';

            	if (c.id !== 'app') {
            		globalThis[c.id].id = c.id;
            		globalThis.componentQueue = globalThis.componentQueue || []; globalThis.componentQueue.push(globalThis[c.id]);
            	} else {
            		globalThis.app.id = 'app';
            		globalThis.componentQueue = globalThis.componentQueue || [];
    				globalThis.componentQueue.push(globalThis.app);
            	}
            }));
        });

        await Promise.all(promises);

        document.body.removeChild(launchWait);
        document.body.removeChild(errorMsg);

        if (window.app && window.app.start) {
            window.app.start();
        }
    }).catch(e => {
        logger.log('js/main.js: error loading resources', e);
        launchWait.style.display = 'none';
        errorMsg.style.display = 'block';
    });
}

loadResources();